import "../styles/SavoirFaire.css"
import { useState } from 'react'
import { useRef } from 'react'

function HA() {

    const videoRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    
    return (
        <div>
            <div className="HA">
                <p className='introduction' id='intro-1'>Heloise et Abelard is the french leading marriage proposal agency.</p>

                <p className='introduction'>Trusted since 2011 for its confidentiality and its exclusive savoir-faire, the agency became the open secret of leaders, athletes and anyone seeking to create everlasting moments in the name of love.</p>

                <p className='introduction'>Built around experts in scenography, cinema-graded decorators, designers and a passionate groups of creatives, the agency dominates a large range of productions techniques to empower the most delicate and the most spectacular requests.</p>

                <p className='introduction'>In 2023, Heloise et Abelard created Les Escapades. The latest signature of the agency curates travel experiences around the globe and uncovers the most exclusive travel gems.</p>
            </div>

        </div>
    )

}

export default HA