import '../styles/Home.css'
import { Link } from 'react-router-dom'
import SavoirFaire from "../assets/croquis.jpg"
import LesEscapades from "../assets/escapades.jpg"
import Roses from "../assets/roses.jpg"
import Portrait from "../assets/portrait.jpg"


function Home() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,

        });
    };

    return (
        <div className='home'>
            <div className='accroche'>
                <h2>'I TRULY BELIEVE IN CREATING MOMENTS WHERE LOVE FEELS LIKE A DREAM AND MAGIC BECOMES REALITY'</h2>
                <h1 className="brand-name">HELOISE & ABELARD</h1>
            </div>
            <div className='accueil'>
                <div className='presentation-1'>
                    <h3>Heloise & Abelard, a new version of love</h3>
                    <p>The art of the marriage proposal, redefined—where every detail is woven into a unique celebration of love. Heloise and Abelard blend passion and creativity to create timeless moments, where dreams are brought to life.</p>
                    <h3>Elegance in every detail</h3>
                    <p>At Heloise and Abelard, we specialize in three distinct areas: refined proposals, expert savoir-faire, and exclusive escapes. Each of these elements is designed to offer a unique and memorable experience, blending sophistication with personal touches to make every moment exceptional.</p>
                </div>
                <div className='menu-photo'>
                    {/*<div className='contacts'>
                        <p className='lien-contacts'><Link to="/contact">GALLERY</Link></p>
                        <p className='lien-contacts'><Link to="/contact">CONTACTS</Link></p>
                    </div>*/}
                    <div className='services'>
                        <div className='savoir-faire'>
                            <p className='image-description'>SAVOIR FAIRE</p>
                            <Link to="/savoir-faire" onClick={scrollToTop}><img className="img-savoir-faire" src={SavoirFaire} alt="photo des planches de croquis" /></Link>
                            <p className='read-more'>Heloise et Abelard is the french leading marriage proposal agency. Trusted since 2011 for its confidentiality and its exclusive savoir-faire ... <Link to="/savoir-faire" onClick={scrollToTop}>Read more</Link></p>
                        </div>
                        <div className='h-et-a'>
                            <p className='image-description'>THE PROPOSAL</p>
                            <Link to="/the-proposal" onClick={scrollToTop}><img className="img-savoir-faire" src={Roses} alt="photo d'un bouquet de roses rouges'" /></Link>
                            <p className='read-more'>The most beautiful words a man could say deserve a special moment... <Link to="/the-proposal" onClick={scrollToTop}>Read more</Link></p>
                        </div>
                        <div className='les-escapades'>
                            <p className='image-description'>LES ESCAPADES</p>
                            <Link to="/escapades" onClick={scrollToTop}><img className="img-les-escapades" src={LesEscapades} alt="photo d'un lieu privé'" /></Link>
                            <p className='read-more'>Immerse yourself in the exclusive universe of Heloise and Abelard with our 'Les Escapades' service — an experience designed to elevate the precious moments in your life ... <Link to="/escapades" onClick={scrollToTop}>Discover how</Link></p>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )

}

export default Home