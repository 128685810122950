import "../styles/Footer.css"
import { Link } from "react-router-dom"

function Footer() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,

        });
    };

    return (
        <div className="footer">
            <div className="quick-description">
                <p>Heloise et Abelard is a premier marriage proposal agency known for creating unforgettable romantic moments. We offer bespoke proposals and exclusive "Les Escapades" experiences, turning dreams into reality with creativity and elegance.</p>
            </div>
            <div className="rights">
                <Link to="/policy" onClick={scrollToTop} className="private-policy">Private Policy</Link>
                <p>© 2024, Heloise & Abelard.
                All rights reserved.</p>
            </div>
            <div className="footer-contacts">
                <a aria-label="Contact us by email" href="mailto:contact@heloiseetabelard.com">Email</a>
                <a href="https://www.instagram.com/heloiseetabelard_/" aria-label="Follow us on Instagram" target="_blank">Instagram</a>
            </div>
            <div className="formulaire">
                <Link to ="/contact" onClick={scrollToTop} aria-label="Contact us for any inquiries">Contact us</Link>
            </div>
        </div>
    )

}

export default Footer