import "../styles/Policy.css"

function Policy() {
    return (

       <div className="policy">
            <p>Privacy Policy<br />
            <br />
            Heloise et Abelard is committed to protecting your privacy and ensuring the security of your personal information.<br />
            This Privacy Policy explains how we collect, use, and protect your data when you use our website and services.<br />
            <br />
            Company Identification<br />
            Company Name: Heloise et Abelard<br />
            Address: 229 rue Saint-Honoré, 75001 Paris, France<br />
            Phone: +33 6 88 255 149<br />
            Website: https://heloiseetabelard.com/<br />
            Instagram: https://www.instagram.com/heloiseetabelard_/<br />
            <br />
            Information Collected<br />
            We collect the following information through our contact form:<br />
            First Name<br />
            Last Name<br />
            Phone Number<br />
            <br />
            Use of Information<br />
            The information we collect is used to:<br />
            Contact the client: We use your data to call you back regarding your information request.<br />
            <br />
            Sharing of Information<br />
            We do not share your personal data with third parties. Your information is used solely by Héloïse et Abélard for the purposes mentioned above.
            <br />
            Security of Information<br />
            We implement appropriate security measures to protect your personal data against loss, misuse, and unauthorized access. We are committed to ensuring the security of your information.<br />
            <br />
            User Rights<br />
            You have the right to:<br />
            Access your personal data that we hold.<br />
            Correct any inaccurate or incomplete information.<br />
            Delete your personal data from our system.<br />
            Contact Héloïse et Abélard to exercise your rights or for any questions regarding your personal data.<br />
            <br />
            Cookies and Similar Technologies<br />
            We do not use cookies on our website. All interactions are conducted solely through contact forms.<br />
            <br />
            Changes to the Policy<br />
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. The date of the last update will be indicated at the top of this page.<br />
            <br />
            Contact<br />
            For any questions regarding this Privacy Policy or to exercise your rights, please contact us at:<br />
            Email: contact@heloiseetabelard.com<br />
            Address: 229 rue Saint-Honoré, 75001 Paris, France<br />
            Phone: +33 6 88 255 149</p>
       </div> 

    )
}

export default Policy