import "../styles/TheProposal.css"
import { useState } from 'react'
import NeneShort from "../assets/videos/NENEVDEF.mp4"
import NeneMiniature from "../assets/nene-miniature.png"
import HernaniShort from "../assets/videos/hernani.mp4"
import HernaniMiniature from "../assets/Hernani-miniature.png"

function TheProposal() {

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedPoster, setSelectedPoster] = useState(null);

    return (

        <div>
            <div className="the-proposal">
                The most beautiful words a man could say deserve a special moment.<br/>
                <br/>
                When two souls harmonize in one another’s presence and decides to unite for eternity it all starts with a few magical words. The proposal, an invite for infinity.<br/>
                <br/>
                Heloise et Abelard is specialized since 2011 in empowering sublime moments.<br/>
                <br/>
                Tailor-made, created from a blank page and subtly colored of your memories and every symbol which makes your relation to be unique, our marriage proposals are always meant to reflect the essence of your love.<br/>
                <br/>
            </div>
            <div className="gallery">
                <p>Courtesy of our clients:</p>
                <div className="gallery-images">
                    <div className="gallery-item" onClick={() => { setSelectedVideo(NeneShort); setSelectedPoster(NeneMiniature); }}>
                        <img src={NeneMiniature} alt="New-York" />
                        <p>New-York</p>
                    </div>
                    <div className="gallery-item" onClick={() => { setSelectedVideo(HernaniShort); setSelectedPoster(HernaniMiniature); }}>
                        <img src={HernaniMiniature} alt="Santorini" />
                        <p>Santorini</p>
                    </div>
                </div>
            </div>

            {selectedVideo && (
                <div className="modal" onClick={() => { setSelectedVideo(null); setSelectedPoster(null); }}>
                    <div className="modal-content">
                        <span className="close-button" onClick={() => { setSelectedVideo(null); setSelectedPoster(null); }}>&times;</span>
                        <video className="video-nene" controls width="800" height="450" poster={selectedPoster} onClick={(e) => e.stopPropagation()}>
                            <source src={selectedVideo} type="video/mp4" />
                            Votre navigateur ne prend pas en charge la lecture de vidéos.
                        </video>
                    </div>
                </div>
            )}

        </div>

    )

}

export default TheProposal