import '../styles/App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import SavoirFaire from './SavoirFaire';
import Policy from './Policy'
import Header from './Header';
import Contact from './Contact';
import Footer from './Footer';
import Escapades from './Escapades';
import Home from './Home';
import Background from "../assets/fond-rouge-2.jpg"
import Gallery from './TheProposal';
import TheProposal from './TheProposal';

function App() {
  return (
    <div className='app' /*style={{ backgroundImage: `url(${Background})`, backgroundSize:"cover" }}*/>
      <Router>
        <AppRoutes />
      </Router>
    </div>
  );
}

function AppRoutes() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <>
      <div className='app-container'>
        {!isHomePage && <Header />}
        <main className='main-content'>
          <Routes>
            <Route path='/' exact element={<Home />} />
            <Route path='/savoir-faire' exact element={<SavoirFaire />} />
            <Route path="/contact" exact element={<Contact />} />
            <Route path='/policy' exact element={<Policy />} />
            <Route path="/escapades" exact element={<Escapades />} />
            <Route path='/the-proposal' exact element={<TheProposal />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default App;
